import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PersonDetails } from 'src/app/ts-interfaces/order-interface';

@Component({
  selector: "ds-customer-info",
  templateUrl: "./customer-info.component.html",
  styleUrls: ["./customer-info.component.scss"],
})
export class CustomerInfo implements OnInit, OnChanges {
  @Input() pageHeading!: string;
  @Input() personDetails!: PersonDetails;

  public personName = "";
  public personPhone = "";
  public personEmail = "";

  constructor() {}

  ngOnInit(): void {
    this.displayCustomerInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.personDetails && changes.personDetails.currentValue) {
      this.displayCustomerInfo();
    }
  }

  displayCustomerInfo() {
    this.personName = this.personDetails.name;
    this.personPhone = this.personDetails.phone ?? "";
    this.personEmail = this.personDetails.email ?? "";
  }
}
