import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { StreamingRequestHeaders } from "../ts-interfaces/streaming-service.interface";
import { BaseService } from "./base-service";
import { SseService } from "./sse.service";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrackerService extends BaseService {

  constructor(private sseService: SseService) {
    super();
  }

  /**
   * Fetches the live oorder data for standalone application from streaming application
   * Headers are x-token and x-orderid for this api
   * @param orderId Mongo id of the order for which live streaming tracking data is to be fetched
   * @param token JWT token which contains information aboout expiry and orderId
   */
  public subscribeTrackerInfo(orderId: string, token: string, isClientToken: boolean, tenantId: string | undefined, trackingNumber: string | undefined, provider: string | undefined, onChange: (data: any) => void, onError: (err: any) => void) {
    const headers: StreamingRequestHeaders = {
      'x-token': token || 'na',
      'x-orderid': orderId,
      'ngsw-bypass': true
    };

    if (trackingNumber && provider) {
      headers['trackingNumber'] = trackingNumber;
      headers['provider'] = provider;
    }

    if (environment.env !== "local" && !window.location.host.includes('localhost') && !window.location.host.endsWith('deliverysolutions.co')) {
        headers['custom-domain'] = window.location.host;
    }

    if (tenantId) {
      headers['tenantId'] = tenantId;
    }

    return this.sseService.getServerSentEvent(`${this.streamServiceUrl}/tracker/orderId/${orderId}`, onChange, onError, headers);
  }

}
