<div class="main-div">
  <div class="heading">{{ pageHeading }}</div>
  <div class="info-container">
    <div class="img-div">
      <img src="/assets/icons/customer.svg" alt="Customer Icon">
    </div>
    <div class="cust-info">
      <div>{{ personName }}</div>
      <div>{{ personPhone }}</div>
      <div *ngIf="personEmail">{{ personEmail }}</div>
    </div>
  </div>
</div>