<div class="order-card-container d-flex flex-column position-relative padding-for-16-9">
  <div class="details-container p-3 center-absolutely">
    <div>{{pickupHeading}}</div>
    <div class="date-time">{{pickupDate}}</div>
    <div class="pickup-notification">
      <div class="status-icon">
        <i [ngClass]="isPickedup ? 'fa-regular fa-circle-check' : 'fa-regular fa-bell'"></i>
      </div>
      <div class="pickup-status">{{pickupStatus}}</div>
    </div>
  </div>
</div>