import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { FeedBackForm } from "../components/feedback-box/feedback-box.component";
import { BaseService } from "./base-service";

@Injectable({
  providedIn: 'root'
})
export class SharedService extends BaseService {
  public isPreview: boolean = false;
  public fakeSuccess$ = new BehaviorSubject({ message: 'success' });
  private isBulkFeedbackSent = new Subject<{ rating: number, comment?: string }>();
  public isBulkFeedbackSent$ = this.isBulkFeedbackSent.asObservable();
  private isFeedbackSent = new Subject<{ rating: number, comment?: string }>();
  public isFeedbackSent$ = this.isFeedbackSent.asObservable();

  constructor(private http: HttpClient) {
    super();
    this.isPreview = window.location.pathname.includes('preview');
  }

  /**
   * Send bulk feedback for multiple orderIds
   * @param form
   * @param orderIds
   */
  sendBulkFeedback(form: FeedBackForm, orderIds: Array<string>, source: string) {
    if (!this.isPreview) {
      const obj = {
        rating: parseInt(form.rating || ""), // don't really need ' || ""' part. that is just to keep typescript quite
        comment: form.comment,
        source: source,
        orderIds
      }

      return this.http.post(`${this.serverRootPathV2}feedback/bulk`, obj)
        .pipe(tap(() => this.isBulkFeedbackSent.next({ rating: obj.rating, comment: obj.comment })));
    } else {
      return of(this.fakeSuccess$);
    }
  }

  sendFeedback(form: FeedBackForm, orderId: string, source: string) {
    if (!this.isPreview) {
      const obj = {
        rating: parseInt(form.rating || ""), // don't really need ' || ""' part. that is just to keep typescript quite
        comment: form.comment,
        source: source
      }

      return this.http.post(`${this.serverRootPathV2}feedback/${orderId}`, obj)
        .pipe(tap(() => this.isFeedbackSent.next({ rating: obj.rating, comment: obj.comment })));
    } else {
      return of(this.fakeSuccess$);
    }
  }

  cleanPhoneNumber(phone: string): string {
    // Remove spaces, commas, hyphens, and brackets
    return phone.replace(/[\s,\-\(\)]/g, '');
  }

}
