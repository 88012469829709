export const PickupStatusReverseMapping: StringToString = {
  ORDER_DISPATCHED: "Ready for Pickup",
  STAGED: "Ready for Pickup",
  PICKUP_STARTED: "Ready for Pickup",
  PICKUP_NEARBY: "Ready for Pickup",
  PICKUP_AT_LOCATION: "Ready for Pickup",
  ORDER_REDELIVERY: "Ready for Pickup",
  ORDER_DELIVERED: "Picked Up",
};


interface StringToString {
  [key: string]: string;
}