import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ds-order-status-detail-card",
  templateUrl: "./order-status-detail-card.component.html",
  styleUrls: ["./order-status-detail-card.component.scss"],
})
export class OrderStatusDetailCard implements OnInit {
  @Input() pickupHeading!: string;
  @Input() pickupDate!: string;
  @Input() pickupStatus!: string;
  @Input() isPickedup!: boolean;

  constructor() {}
  ngOnInit(): void {}
}
