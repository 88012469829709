<div class="row">
  <h1 *ngIf="showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.groupId}} ( {{ 'GENERIC.PLATFORM.PACKAGE' | translate }} #{{order.orderExternalId}} )
  </h1>
  <h1 *ngIf="!showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.orderExternalId}}
  </h1>
</div>


<!-- Compliance section start -->
<div class="row">
  <div class="col">
    <app-compliance-info-box-section *ngIf="hostedTrackingConfig?.componentVisibility?.showComplianceInfo" [order]="order"
      [hostedTrackingConfig]="hostedTrackingConfig!"></app-compliance-info-box-section>
  </div>
</div>
<!-- Compliance section end --> 

<div class="custom-row align-items-stretch">
  <div class="custom-col-12 custom-col-md-6 pb-14px">
    <ds-order-status-detail-card *ngIf="!isSystemStatus" [pickupHeading]="pickupHeading" [pickupDate]="pickupDate" [isPickedup]="isPickedup"
      [pickupStatus]="pickupStatus" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)"></ds-order-status-detail-card>
    <ds-info-unavailable *ngIf="isSystemStatus"></ds-info-unavailable>

    <!-- Mobile view ad right below the card -->
    <div class="d-md-none mob-view-primary-Ad pt-3" *ngIf="primaryAds">
      <ds-side-ad-banner [adconfig]="primaryAds"></ds-side-ad-banner>
    </div>
  </div>
  <div class="custom-col-12 custom-col-md-6 pb-14px d-none d-md-block desk-view-primary-Ad">
    <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>


<div class="custom-row">
  <div class="custom-col-12 text-lg-left pb-14px">
    <!-- Pickup Status start -->
    <div class="pickup-status-container">
      <div class="pickup-status d-flex flex-column">
        
        <!--New Changes for Pickup store hours-->
        <div class="d-flex flex-column flex-1-1-auto border-bottom-1px position-relative">
          <ds-pickup-store-details [order]="order" [hostedTrackingConfig]="hostedTrackingConfig">            
          </ds-pickup-store-details>          
        </div>
        <!--New Changes for Pickup store hours ended-->

        <div class="info-wrapper">
          <div class="info-block">
            <ds-customer-info [personDetails]="customerDetails" pageHeading="CUSTOMER INFORMATION"></ds-customer-info>
          </div>
          <div *ngIf="isDriverInfoAvailable" class="info-block add-line">
            <ds-customer-info [personDetails]="driverDetails" pageHeading="PICKUP PERSON"></ds-customer-info>
          </div>
        </div>


        <div class="apps-container px-3">
          <div class="instructions-container d-none" *ngIf="order.pickupInstructions">
            <div class="update-text">
              {{ 'GENERIC.PLATFORM.INSTRUCTIONS' | translate }}
            </div>
            <ul class="instructions pt-0 m-0">
              <li>
                {{order.pickupInstructions}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Pickup Status end -->
  </div>
</div>
