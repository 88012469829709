<div class="main-box">
  <div class="pickup-heading">{{ 'GENERIC.COMMON_PAGES.DELIVERY_BY' | translate | uppercase }}</div>
  <div class="lower-box">
    <div class="img-div">
      <img [src]="brandLogo || '/assets/icons/defaultStore.svg'" width="40" height="40"
        class="rounded-circle">
    </div>
    <div class="second-div">
      <div class="tenant-info">
        <div class="brand-name">{{storeName}}</div>
        <div style="display: flex; margin-bottom: 4px;">
          <div class="map-img"><img src="/assets/icons/location.svg"></div>
          <a class="address-text" target="_blank" [captureEvent]="'click-hosted-tracking-pickup-location-map'"
            [href]="'https://www.google.com/maps/search/?api=1&query='+ order.pickUpAddress.latitude + ',' + order.pickUpAddress.longitude">
            {{storeAddress}}
          </a>
        </div>
        <div style="display: flex;">
          <div class="phone-img"><img src="/assets/icons/smartphone.svg"></div>
          <a class="phone-text" target="_blank" [captureEvent]="'click-hosted-tracking-pickup-location-phone'"
            href="tel:{{order.pickUpContact.phone}}">
            {{pickupContact}}
          </a>
        </div>
      </div>
      <div *ngIf="storeHours.length>0" class="store-hours-info">
        <div class="store-hours">Store Hours</div>
        <div *ngFor="let store of storeHours">
          {{ store.day }}: {{ store.windows }}
        </div>
      </div>
    </div>
  </div>
</div>

