import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { addMinutesToDate } from "../shared/utils/date-utils";
import { Recommendations } from "../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../ts-interfaces/order-interface";
import { BaseService } from "./base-service";
import { OrderTypeController } from '@deliverysolutions/order-status-mapping';
import { TranslationsService } from "./translations.service";
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private translationsService: TranslationsService,
    private translate: TranslateService
  ) {
    super();
  }

  get(orderId?: string): Observable<any | undefined | string> {
    let getOrderUrl = this.serverRootPathV2 + 'hosted-tracking/order';
    getOrderUrl += orderId ? orderId : '';
    return this.httpClient.get(getOrderUrl)
      .pipe(catchError(e => {
        console.log('error in fetching order', e);

        return of(undefined)
      }),
      mergeMap(async (response: any) => {
        if (!response) {
          return response;
        }
        const locale = response?.order?.locale;
        await firstValueFrom(this.setTranslations(locale, ''));
        return response;
      })
    );
  }

  getOrderStatus(orderId: string): Observable<any> {
    const url = this.serverRootPathV2 + 'hosted-tracking/order-status/' + orderId;

    return this.httpClient.get(url)
      .pipe(catchError(e => {
        console.log('error in fetching order', e);
        return of(undefined)
      })
    );
  }

  getFakeOrder(): OrderInterface {
    const currentDate = new Date();
    const order: OrderInterface = {
      _id: new Date().valueOf().toString(),
      estimatedDeliveryTime: addMinutesToDate(currentDate, 10).valueOf(),
      estimatedPickupTime: addMinutesToDate(currentDate, 2).valueOf(),
      carrier: { providerInfo: { id: "634016e254880d095321718d", name: "Self Delivery", displayName: "Self Delivery" } },
      trackingUrl: undefined,
      provider: "Pickup DSP 003",
      storeExternalId: "0003",
      orderExternalId: "Preview-Order",
      type: OrderTypeController.DELIVERY,
      orderValue: 50,
      deliveryContact: {
        name: "John Doe",
        phone: "+1 000-000-0000",
        customerId: "",
        email: "",
      },
      deliveryAddress: {
        street: "5161 San Felipe Street",
        street2: "",
        city: "Houston",
        state: "DC",
        country: "US",
        zipcode: "77056",
        latitude: 29.7492711,
        longitude: -95.46605609999999
      },
      packages: [
        {
          name: "custom",
          size: {
            length: 1,
            width: 1,
            height: 1
          },
          weight: 1,
          items: 1,
          description: ""
        }
      ],
      pickupInstructions: "",
      pickupTime: {
        startsAt: 1618294619385
      },
      dropoffTime: {
        endsAt: 1618294679385
      },
      userPickupTime: 1618294619385,
      timeZone: "America/Los_Angeles",
      pickUpContact: {
        name: "John Doe Pickup",
        phone: "+1 000-000-0000"
      },
      pickUpAddress: {
        street: "1234 Wilshire Boulevard",
        street2: "",
        secondary: "",
        city: "Los Angeles",
        zipcode: "90017",
        country: "US",
        state: "CA",
        latitude: 34.0533056,
        longitude: -118.2661004
      },
      store: {
        name: "Some store"
      },
      status: "OUT_FOR_DELIVERY",
      route: [],
      trackingNumber: "Preview-Order-01",
      brandName: "KP",
      brandExternalId: '',
      statusHistory: [],
      customerWebflowUrl: '',
      isFeedbackShared: false,
      driver: {
        name: 'John Doe',
        phone: '+1 000 000 0000'
      },
      lastLocation: {
        updatedAt: new Date(),
        latitude: 42.688234,
        longitude: -73.817906,
      },
      vehicle: {
        type: "Sedan",
        color: "Black",
        make: "Honda"
      },
      isRx: true,
      isTobacco: true,
      isSpirit: true,
      itemList: [
        {
          sku: "ABCD",
          quantity: 1,
          size: {
            length: 10,
            width: 10,
            height: 10
          },
          weight: 100,
          price: 19.99,
          sale_price: 15.99,
          image: "/assets/icons/package-item-sample.png",
          title: "Product 1"
        },
        {
          sku: "ABCD-2",
          quantity: 1,
          size: {
            length: 15,
            width: 14,
            height: 12
          },
          weight: 30,
          price: 12.99,
          image: "/assets/icons/package-item-sample-2.png",
          sale_price: 8.99,
          title: "Product 2"
        },
        {
          sku: "JKLM-23",
          quantity: 10,
          size: {
            length: 15,
            width: 14,
            height: 12
          },
          weight: 30,
          price: 7.99,
          sale_price: 4.99,
          image: "/assets/icons/package-item-sample-3.png",
          title: "Product 3"
        },
        {
          sku: "XYZ-2",
          quantity: 11,
          size: {
            length: 15,
            width: 14,
            height: 12
          },
          weight: 30,
          price: 9.99,
          image: "/assets/icons/package-item-sample-4.png",
          sale_price: 6.99,
          title: "Product 4"
        },
        {
          sku: "FGHI-13",
          quantity: 13,
          size: {
            length: 15,
            width: 14,
            height: 12
          },
          weight: 30,
          price: 13.99,
          image: "/assets/icons/package-item-sample-5.png",
          title: "Product 5"
        }
      ]
    }

    return order;
  }

  fetchExternalRecommendations(url: string, orderId: string, module: string): Observable<Recommendations[] | [] | undefined> {
    if ((url.includes('https') || url.includes('http')) && !url.includes('deliverysolutions.co')) {
      return this.httpClient.get<Recommendations[] | [] | undefined>(
        `${this.serverRootPathV2}${module}/external-recommendations`,
        { params: { url, orderId } },
      ).pipe(catchError(e => { console.log("error in fetching recommendations", e); return of(undefined) }))
    } else {
      return of([]);
    }

  }

  getOrderFeedback(orderId?: string): Observable<any | undefined | string> {
    let getOrderUrl = this.serverRootPathV2 + 'hosted-feedback/order';
    getOrderUrl += orderId ? orderId : '';
    return this.httpClient.get(getOrderUrl)
      .pipe(catchError(e => {
        console.log('error in fetching order', e);

        return of(undefined)
      }));
  }

  private setTranslations(
    locale: string = '',
    hToken: string = ''
  ): Observable<any> {
    try {
      const urlPath = window.location.pathname.substr(1);
      const urlArray = urlPath && urlPath.split('/');
      const isFeedbackUrl = NullEmptyChecker.isNonEmptyArray(urlArray) && urlArray[0] === 'feedback';
      const isFeedbackPreviewUrl = NullEmptyChecker.isNonEmptyArray(urlArray) && urlArray[0] === 'feedback' && urlArray[1] === "preview";
      const index = isFeedbackPreviewUrl ? 2 : (isFeedbackUrl ? 1 : 0);
      const tenantId = urlArray[index] || "";
      const brandExternalId = urlArray[index + 1];
      const params: any = new URLSearchParams(window.location.search);
      if (params.get('token')) {
        hToken = params.get('token')
      }
      return this.translationsService.get(locale, hToken, tenantId, brandExternalId).pipe(
        map((translations) => {
          if (translations) {
            this.translate.setTranslation(locale, translations, true);
            this.translate.use(locale);
          }
          return locale;
        })
      );
    } catch (error) {
      console.error(error);
      return of(undefined);
    }
  }

  getStoreOperationalHours(storeExternalId: string, startDate: string): Observable<any> {
    const params = {
      storeExternalId,
      startDate,
    };

    const storeHoursUrl = this.serverRootPathV2 + "hosted-tracking/timings/store/operational-hours";

    return this.httpClient.get(storeHoursUrl, { params });
  }
}
