import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DeliveryOrderDetailsComponent } from "./components/delivery-order-details/delivery-order-details.component";
import { FeedbackBoxComponent } from "./components/feedback-box/feedback-box.component";
import { HomeComponent } from "./components/home/home.component";
import { OrderDetailsComponent } from "./components/order-details/order-details.component";
import { PackageItemListComponent } from "./components/package-item-list/package-item-list.component";
import { PickupOrderDetailsComponent } from "./components/pickup-order-details/pickup-order-details.component";
import { PickupOrderDetailsNewComponent } from './components/pickup-order-details-new/pickup-order-details-new.component';
import { CustomerInfo } from './components/customer-info/customer-info.component';
import { PickupFromNewComponent } from './components/pickup-store-details/pickup-store-details.component';
import { RecommendationsComponent } from "./components/recommendations/recommendations.component";
import { ShipmentOrderDetailsComponent } from "./components/shipment-order-details/shipment-order-details.component";
import { SideAdBannerComponent } from "./components/side-ad-banner/side-ad-banner.component";
import { SiteFooterComponent } from "./components/site-footer/site-footer.component";
import { SiteHeaderComponent } from "./components/site-header/site-header.component";
import { SubscribeToUpdatesComponent } from "./components/subscribe-to-updates/subscribe-to-updates.component";
import { WideAdsBannerComponent } from "./components/wide-ads-banner/wide-ads-banner.component";
import { ReactiveFormsModule } from '@angular/forms';
import { OrderStatusCardComponent } from './components/order-status-card/order-status-card.component';
import { OrderStatusDetailCard } from './components/order-status-detail-card/order-status-detail-card.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BroadcastMessageComponent } from './components/broadcast-message/broadcast-message.component';
import { HeadersInterceptor } from './services/interceptors/http-headers.interceptor';
import { FormsModule } from '@angular/forms';
import { OrderInfoUnavailableComponent } from './components/order-info-unavailable/order-info-unavailable.component';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { ServiceUnavailableComponent } from './components/service-unavailable/service-unavailable.component';
import { OrderStatusMappingPipe } from "./pipes/order-status-mapping.pipe";
import { PreviewComponent } from './components/preview/preview.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { StoreDriverInfoComponent } from './components/store-driver-info/store-driver-info.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { StatusHistoryComponent } from './components/status-history/status-history.component';
import { ImagesModalComponent } from './components/images-modal/images-modal.component';
import { EntryComponent } from './components/entry/entry.component';
import { FeedbackPageComponent } from './components/feedback-page/feedback-page.component';
import { PreviewHostedFeedbackComponent } from './components/preview-hosted-feedback/preview-hosted-feedback.component';
import { ComplianceSectionComponent } from './components/compliance-info/compliance-info.component';
import { CaptureEventDirective } from "./app.directive";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StatusHistoryThumbnailComponent } from './components/status-history-thumbnail/status-history-thumbnail.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=2024-07-29');
}

export function initTranslate(translate: TranslateService) {
  return () => {
    translate.addLangs(["en"]);
    translate.setDefaultLang("en");
    return translate.use("en").pipe(
      catchError(error => {
        console.error('Error loading in Translations json', error);
        return of(null); 
      })
    ).toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SideAdBannerComponent,
    SiteHeaderComponent,
    BroadcastMessageComponent,
    WideAdsBannerComponent,
    SiteFooterComponent,
    RecommendationsComponent,
    PackageItemListComponent,
    FeedbackBoxComponent,
    SubscribeToUpdatesComponent,
    OrderDetailsComponent,
    PickupOrderDetailsComponent,
    PickupOrderDetailsNewComponent,
    PickupFromNewComponent,
    CustomerInfo,
    ShipmentOrderDetailsComponent,
    DeliveryOrderDetailsComponent,
    OrderStatusCardComponent,
    OrderStatusDetailCard,
    PageNotFoundComponent,
    OrderInfoUnavailableComponent,
    ImageFallbackDirective,
    ServiceUnavailableComponent,
    OrderStatusMappingPipe,
    PreviewComponent,
    PreviewHostedFeedbackComponent,
    CookieConsentComponent,
    StoreDriverInfoComponent,
    GoogleMapComponent,
    StatusHistoryComponent,
    StatusHistoryThumbnailComponent,
    ImagesModalComponent,
    EntryComponent,
    FeedbackPageComponent,
    ComplianceSectionComponent,
    CaptureEventDirective
  ],
  imports: [
    NgbModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    NgxUsefulSwiperModule,
    BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			}
		}),
  ],

  providers: [HttpClientModule, { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
     {
      provide: APP_INITIALIZER,
      useFactory: initTranslate,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
