import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { HostedTrackingConfig, AdConfig } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface, StatusHistory, PersonDetails } from "../../ts-interfaces/order-interface";
import { PickupStatusReverseMapping } from "src/app/shared/constants/pickup-reverse-status";
import { StatusGroupController } from "@deliverysolutions/order-status-mapping";
import { TerminalStatus } from "src/app/shared/constants/terminal-status";
import { SharedService } from "src/app/services/shared.service";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment-timezone";

@Component({
  selector: "ds-pickup-order-details-new",
  templateUrl: "./pickup-order-details-new.component.html",
  styleUrls: ["./pickup-order-details-new.component.scss"],
})
export class PickupOrderDetailsNewComponent implements OnInit, OnChanges {
  @Input() order!: OrderInterface;
  @Input() hostedTrackingConfig!: HostedTrackingConfig;
  @Input() isSystemStatus!: boolean;
  @Input() isTerminalStatus!: boolean;
  @Input() showSiblingOrders!: boolean;
  @Output() openDeliveryProofsModal: EventEmitter<boolean> = new EventEmitter();

  public primaryAds: AdConfig | undefined;
  statusHistory: StatusHistory[] = [];

  public isDriverInfoAvailable = false;
  public customerDetails: PersonDetails = { name: "", phone: "", email: "" };
  public driverDetails: PersonDetails = { name: "", phone: "", email: "" };

  private statusGroup = new StatusGroupController();

  public pickupHeading = "Pickup Date";
  public pickupDate = "";
  public pickupStatus = "";
  public isPickedup = false;

  constructor(private translate: TranslateService, private sharedService: SharedService) {}

  ngOnInit(): void {
    // primary ad is mandatory
    this.primaryAds = this.hostedTrackingConfig.components.ads.find((ads) => {
      return ads.active && ads.type === "primary";
    });

    this.getPickupCustomerInfo();
    this.updateOrderStatusData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order && changes.order.currentValue) {
      this.updateOrderStatusData();
      this.getPickupCustomerInfo();
    }
  }

  getPickupCustomerInfo() {
    if (this.order.driver && this.order.driver?.name) {
      this.isDriverInfoAvailable = true;

      this.driverDetails = {
        name: this.order.driver.name ?? "",
        phone: this.order.driver.phone ? this.sharedService.cleanPhoneNumber(this.order.driver.phone) : "",
        email: "",
      };
    } else {
      this.isDriverInfoAvailable = false;
    }

    if (this.order.deliveryContact) {
      this.customerDetails = {
        name: this.order.deliveryContact.name ?? "",
        phone: this.order.deliveryContact.phone
          ? this.sharedService.cleanPhoneNumber(this.order.deliveryContact.phone)
          : "",
        email: this.order.deliveryContact.email ?? "",
      };
    }
  }

  // Updates pickup heading, date, and status string based on order status and timestamps
  private updateOrderStatusData(): void {
    if (TerminalStatus.includes(this.order.status)) {
      this.handleTerminalStatus();
    } else {
      this.handleNonTerminalStatus();
    }
  }

  // Generates a display-friendly object for weekday, month, day, and time
  private generateTimeToShow(timestamp: number | string) {
    const date = moment(timestamp);
    const match = date
      .tz(this.order.timeZone)
      .format("ddd, MMM D, YYYY, hh:mm A")
      .match(/^(\w+), (\w+) (\d+), \d+, (\d+:\d+ (?:A|P)M)$/);

    if (!match) return;

    return {
      weekday: this.translate.instant(`HOSTED_TRACKING.WEEKDAY_ABBREVIATIONS.${match[1].toUpperCase()}`),
      month: this.translate.instant(`HOSTED_TRACKING.MONTH_ABBREVIATIONS.${match[2].toUpperCase()}`),
      day: match[3],
      time: match[4],
    };
  }

  // Handles logic when order has a terminal status (like DELIVERED)
  private handleTerminalStatus(): void {
    const statusIndex = this.order.statusHistory.findIndex((entry) => entry.status === this.order.status);

    if (statusIndex === -1) return;

    const updatedAt = this.order.statusHistory[statusIndex]?.updatedAtEpoch;
    const timeObj = this.generateTimeToShow(updatedAt);
    if (!timeObj) return;

    const label =
      this.statusGroup.getDisplayName(this.order.status, this.order.type) ||
      this.translate.instant("GENERIC.PLATFORM.NOT_APPLICABLE");

    this.pickupDate = `${timeObj.weekday.substring(0, 3)}, ${timeObj.month.substring(0, 3)} ${timeObj.day}`;
    this.pickupStatus = `${label} at ${timeObj.time}`;
    this.isPickedup = true;
  }

  // Handles logic for non-terminal orders (e.g., Ready for Pickup)

  private handleNonTerminalStatus(): void {
    const formatDate = (pickup: any): string =>
      `${pickup.weekday.substring(0, 3)}, ${pickup.month.substring(0, 3)} ${pickup.day}`;

    const formatTimeRange = (start: any, end: any): string =>
      `${start.weekday.substring(0, 3)}, ${start.month.substring(0, 3)} ${start.day}, ${start.time} - ` +
      `${end.weekday.substring(0, 3)}, ${end.month.substring(0, 3)} ${end.day}, ${end.time}`;

    const formatSameDayRange = (start: any, end: any): string =>
      `${start.weekday.substring(0, 3)}, ${start.month.substring(0, 3)} ${start.day}, between ` +
      `${start.time} - ${end.time}`;

    const { estimatedPickupTime, pickupTime, createdAt } = this.order;

    const currentStatus = PickupStatusReverseMapping[this.order.status];

    if (estimatedPickupTime) {
      const start = this.generateTimeToShow(estimatedPickupTime);
      if (start) {
        this.pickupDate = formatDate(start);
        this.pickupStatus = `${currentStatus} After ${start.time}`;
      }
      return;
    }

    if (pickupTime?.startsAt && pickupTime?.endsAt) {
      const start = this.generateTimeToShow(pickupTime.startsAt);
      const end = this.generateTimeToShow(pickupTime.endsAt);

      if (start && end) {
        const sameDay = start.day === end.day && start.month === end.month;
        this.pickupDate = sameDay ? formatSameDayRange(start, end) : formatTimeRange(start, end);
        this.pickupStatus = currentStatus;
        this.pickupHeading = sameDay ? "Pickup Date" : "Pickup Between";
      }
      return;
    }

    if (pickupTime?.startsAt) {
      const start = this.generateTimeToShow(pickupTime.startsAt);
      if (start) {
        this.pickupDate = formatDate(start);
        this.pickupStatus = `${currentStatus} After ${start.time}`;
      }
      return;
    }

    if (createdAt) {
      const start = this.generateTimeToShow(new Date(createdAt).getTime());
      if (start) {
        this.pickupDate = formatDate(start);
        this.pickupStatus = `${currentStatus} After ${start.time}`;
      }
    }
  }
}
