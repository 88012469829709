import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { OrderService } from "src/app/services/order.service";
import { StoreHoursApiResponse, StoreHoursWindow, OrderInterface } from "src/app/ts-interfaces/order-interface";
import { HostedTrackingConfig } from "src/app/ts-interfaces/hosted-tracking-config";
import { WindowTime } from "src/app/ts-interfaces/order-interface";
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "ds-pickup-store-details",
  templateUrl: "./pickup-store-details.component.html",
  styleUrls: ["./pickup-store-details.component.scss"],
})
export class PickupFromNewComponent implements OnInit, OnChanges {
  @Input() order!: OrderInterface;
  @Input() hostedTrackingConfig: HostedTrackingConfig | undefined;

  public storeHours: StoreHoursWindow[] = [];
  public storeAddress: string = "";
  public pickupContact: string = "";
  public storeName: string = "";
  public brandLogo: string = "";

  constructor(private orderService: OrderService, private sharedService: SharedService) {}

  ngOnInit(): void {
    this.displayPickupAdressInfo();
    this.getStoreHoursInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order && changes.order.currentValue) {
      this.displayPickupAdressInfo();
      this.getStoreHoursInput();
    }
  }

  displayPickupAdressInfo() {
    this.storeAddress = this.order?.pickUpAddress?.street;
    if (this.order.pickUpAddress.street2) {
      this.storeAddress += ", " + this.order.pickUpAddress.street2;
    }
    this.storeAddress = `${this.storeAddress}, ${this.order.pickUpAddress.city}, ${this.order.pickUpAddress.state} ${this.order.pickUpAddress.zipcode}, ${this.order.pickUpAddress.country}`;

    this.pickupContact = this.order?.pickUpContact?.phone
      ? this.sharedService.cleanPhoneNumber(this.order?.pickUpContact?.phone)
      : "";
    this.storeName = this.order?.store?.name ?? "";
    this.brandLogo = this.hostedTrackingConfig?.brandLogo ?? "";
  }

  getStoreHoursInput() {
    const storeExternalId = this.order.storeExternalId;
    const today = new Date().toISOString().split("T")[0];

    this.orderService.getStoreOperationalHours(storeExternalId, today).subscribe({
      next: (response) => {
        const jsonInput: StoreHoursApiResponse[] = response;
        if (NullEmptyChecker.isNonEmptyArray(jsonInput)) {
          this.storeHours = this.transformLocationWindows(jsonInput);
        }
      },
      error: (err) => {
        console.error("Error fetching store hours:", err);
      },
    });
  }

  formatTime(timeStr: string | null) {
    if (!timeStr) return "";
    const [hour, minute] = timeStr.split(":").map(Number);
    const ampm = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${ampm}`;
  }

  transformLocationWindows(input: StoreHoursApiResponse[]) {
    const dayMap = {
      Mo: "Monday",
      Tu: "Tuesday",
      We: "Wednesday",
      Th: "Thursday",
      Fr: "Friday",
      Sa: "Saturday",
      Su: "Sunday",
    };

    const inputMap: { [key: string]: StoreHoursApiResponse } = {};
    input.forEach((entry) => {
      inputMap[entry.day] = entry;
    });

    const result = [];
    let isAllSevenDays = true;
    let allDaysHave24HoursOpen = true;

    for (const [alias, day] of Object.entries(dayMap)) {
      const entry = inputMap.hasOwnProperty(alias) ? inputMap[alias] : undefined;
      if (!entry) {
        result.push({ day, alias, windows: "Closed" });
        isAllSevenDays = false;
        allDaysHave24HoursOpen = false;
        continue;
      }

      const windows = entry.windows;
      const is24Hours = windows.every((w: WindowTime) => w.opensAt === null && w.closesAt === null);

      if (is24Hours) {
        result.push({ day, alias, windows: "Open 24 Hours" });
      } else {
        allDaysHave24HoursOpen = false;
        const formattedWindows = windows
          .map((w: WindowTime) => `${this.formatTime(w.opensAt)} - ${this.formatTime(w.closesAt)}`)
          .join(", ");
        result.push({ day, alias, windows: formattedWindows });
      }
    }

    if (isAllSevenDays && allDaysHave24HoursOpen) {
      return [
        {
          day: "Mon-Sun",
          alias: "Mo-Su",
          windows: "Open 24 Hours",
        },
      ];
    }

    return result;
  }
}
