import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
export function transformPayloadFormat(data: any) {
  try {
    const { orderDetails } = data;
    if (orderDetails.hostedTrackingBranding && orderDetails.hostedTrackingConfig) {
      let googleMapsApiKey = orderDetails.googleMapsApiKey;
      if (environment.env === "local") {
        googleMapsApiKey = environment.googleMapsAPIKey;
      } else if (googleMapsApiKey) {
        googleMapsApiKey = decryptMapsApiKey(googleMapsApiKey);
      }
      data = {
        ...data,
        hostedTrackingConfig: {
          googleMapsApiKey: googleMapsApiKey,
          ...orderDetails.hostedTrackingBranding,
          ...orderDetails.hostedTrackingConfig.data,
          brandExternalId: orderDetails.hostedTrackingConfig.brandExternalId,
          brandName: orderDetails.hostedTrackingConfig.brandName,
          brandLogo: orderDetails.hostedTrackingConfig.brandLogo,
        },
        orderDetails: {
          data: { ...orderDetails.order },
        },
      };
    }
    return data;
  } catch (error) {
    console.error('error in converting payload to backward compatible format: ', error);
  }

}

function decryptMapsApiKey(googleMapsApiKey: string): string {
  return CryptoJS.AES.decrypt(googleMapsApiKey, environment.googleMapsApiKeyDecryptionPassphrase).toString(CryptoJS.enc.Utf8);
}